import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const checkIfFullFridge = () => {
  if (publicRuntimeConfig.fullFridge === "fridge") return true;
  return false;
};

export default checkIfFullFridge;
