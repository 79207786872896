import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const Loader = ({ ...rest }) => (
  <Flex flex={1} alignItems="center" justifyContent="center" p="30px" {...rest}>
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="green.500"
      size="xl"
    />
  </Flex>
);

export default Loader;
